<h5>Customize AdminLTE</h5>
<hr class="mb-2"/>
<div style="padding: 8px 0">
  <pf-checkbox (change)="handleDarkModeChange($event)">
    Dark mode
  </pf-checkbox>
  <pf-select
    (change)="onNavbarVariantChange($event)"
    [options]="navbarLightVariants"
    [value]="navbarVariant"
    class="mt-3"
    label="Light Navbar Variants"
    type="custom"
  >
  </pf-select>
  <pf-select
    (change)="onNavbarVariantChange($event)"
    [options]="navbarDarkVariants"
    [value]="navbarVariant"
    class="mt-3"
    label=" Dark Navbar Variants"
    type="custom"
  >
  </pf-select>
  <pf-select
    [options]="[]"
    class="mt-3"
    disabled="true"
    label="Accent Color Variants"
    type="custom"
  >
  </pf-select>
  <pf-select
    (change)="onSidebarSkinChange($event)"
    [options]="lightSidebarSkins"
    [value]="sidebarSkin"
    class="mt-3"
    label="Light Sidebar Variants"
    type="custom"
  >
  </pf-select>
  <pf-select
    (change)="onSidebarSkinChange($event)"
    [options]="darkSidebarSkins"
    [value]="sidebarSkin"
    class="mt-3"
    label="Dark Sidebar Variants"
    type="custom"
  >
  </pf-select>

  <pf-select
    [options]="[]"
    class="mt-3"
    disabled="true"
    label="Brand Logo Variants"
    type="custom"
  >
  </pf-select>
</div>
