<pf-dropdown hide-arrow>
  <div slot="button">
    <a class="nav-link">
      <i class="far fa-comments"></i>
      <span class="badge badge-danger navbar-badge">3</span>
    </a>
  </div>
  <div slot="menu">
    <a class="dropdown-item" href="#">
      <div class="media">
        <pf-image
          alt="User Avatar"
          class="mr-2"
          rounded
          src="assets/img/default-profile.png"
          width="50"
        ></pf-image>
        <div class="media-body">
          <h3 class="dropdown-item-title">
            Brad Diesel
            <span class="float-right text-sm text-danger"
            ><i class="fas fa-star"></i
            ></span>
          </h3>
          <p class="text-sm">Call me whenever you can...</p>
          <p class="text-sm text-muted">
            <i class="far fa-clock mr-1"></i> 4 Hours Ago
          </p>
        </div>
      </div>
      <!-- Message End -->
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#">
      <!-- Message Start -->
      <div class="media">
        <pf-image
          alt="User Avatar"
          class="mr-2"
          rounded
          src="assets/img/default-profile.png"
          width="50"
        ></pf-image>
        <div class="media-body">
          <h3 class="dropdown-item-title">
            John Pierce
            <span class="float-right text-sm text-muted"
            ><i class="fas fa-star"></i
            ></span>
          </h3>
          <p class="text-sm">I got your message bro</p>
          <p class="text-sm text-muted">
            <i class="far fa-clock mr-1"></i> 4 Hours Ago
          </p>
        </div>
      </div>
      <!-- Message End -->
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#">
      <div class="media">
        <pf-image
          alt="User Avatar"
          class="mr-2"
          rounded
          src="assets/img/default-profile.png"
          width="50"
        ></pf-image>
        <div class="media-body">
          <h3 class="dropdown-item-title">
            Nora Silvester
            <span class="float-right text-sm text-warning"
            ><i class="fas fa-star"></i
            ></span>
          </h3>
          <p class="text-sm">The subject goes here</p>
          <p class="text-sm text-muted">
            <i class="far fa-clock mr-1"></i> 4 Hours Ago
          </p>
        </div>
      </div>
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item dropdown-footer" href="#">See All Messages</a>
  </div>
</pf-dropdown>
