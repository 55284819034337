<a
  (click)="handleMainMenuAction()"
  [ngClass]="{active: isMainActive || isOneOfChildrenActive}"
  class="nav-link"
>
  <i class="nav-icon {{ menuItem.iconClasses }}"></i>
  <p>{{ menuItem.name }}
    <i
      *ngIf="isExpandable && menuItem.children.length > 0"
      [@rotate]="isMenuExtended"
      class="right fas fa-angle-right"
    ></i>
  </p>
</a>
<ul *ngFor="let item of menuItem.children" [@openClose]="isMenuExtended" class="nav nav-treeview">
  <li class="nav-item">
    <a
      [routerLinkActive]="'active'"
      [routerLink]="item.path"
      class="nav-link"
    >
      <i class="nav-icon {{ item.iconClasses }}"></i>
      <p>{{ item.name }}</p>
    </a>
  </li>
</ul>
