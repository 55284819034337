import {Component} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
  selector: 'privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent {
  public currentYear: string = DateTime.now().toFormat('y');
}
