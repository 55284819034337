<pf-dropdown hide-arrow>
  <div slot="button">
    <a class="nav-link">
      <i class="far fa-bell"></i>
      <span class="badge badge-warning navbar-badge">15</span>
    </a>
  </div>
  <div slot="menu">
    <span class="dropdown-item dropdown-header">15 Notifications</span>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#">
      <i class="fas fa-envelope mr-2"></i> 4 new messages
      <span class="float-right text-muted text-sm">3 mins</span>
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#">
      <i class="fas fa-users mr-2"></i> 8 friend requests
      <span class="float-right text-muted text-sm">12 hours</span>
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#">
      <i class="fas fa-file mr-2"></i> 3 new reports
      <span class="float-right text-muted text-sm">2 days</span>
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item dropdown-footer" href="#"
    >See All Notifications</a
    >
  </div>
</pf-dropdown>

<ng-template #notification>
  <a class="dropdown-item" href="#">
    <i class="fas fa-envelope mr-2"></i> 4 new messages
    <span class="float-right text-muted text-sm">3 mins</span>
  </a>
  <div class="dropdown-divider"></div>
</ng-template>
