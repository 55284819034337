<pf-dropdown #dropdown [openOnButtonClick]="false" hide-arrow>
  <div slot="button">
    <div class="input-group">
      <input
        (input)="handleSearchTextChange($event)"
        [value]="searchText"
        aria-label="Search"
        class="form-control form-control-sidebar"
        placeholder="Search"
        type="text"
      />
      <div class="input-group-append">
        <button (click)="handleIconClick()" class="btn btn-sidebar">
          <i
            [ngClass]="{
                            fas: true,
                            'fa-search': searchText.length === 0,
                            'fa-times': searchText.length > 0,
                            'fa-fw': true
                        }"
          ></i>
        </button>
      </div>
    </div>
  </div>
  <div class="menu" slot="menu">
    <div *ngIf="foundMenuItems.length === 0" class="nothing-found">
      No Element found
    </div>

    <div *ngIf="foundMenuItems.length > 0" class="list-group">
      <a
        (click)="handleMenuItemClick()"
        *ngFor="let menuItem of foundMenuItems"
        [routerLink]="menuItem.path"
        class="list-group-item"
      >
        <div
          [innerHTML]="boldString(menuItem.name, searchText)"
          class="search-title"
        ></div>
        <div class="search-path">{{ menuItem.name }}</div>
      </a>
    </div>
  </div>
</pf-dropdown>
