<pf-dropdown hide-arrow>
  <div slot="button">
    <a class="nav-link">
      <i class="flag-icon flag-icon-tr"></i>
    </a>
  </div>
  <div slot="menu">
    <a class="dropdown-item active" href="#">
      <i class="flag-icon flag-icon-tr mr-2"></i>
      <span>Turkish</span>
    </a>
    <a class="dropdown-item" href="#">
      <i class="flag-icon flag-icon-us mr-2"></i>
      <span>English</span>
    </a>
    <a class="dropdown-item" href="#">
      <i class="flag-icon flag-icon-de mr-2"></i>
      <span>German</span>
    </a>
    <a class="dropdown-item" href="#">
      <i class="flag-icon flag-icon-fr mr-2"></i>
      <span>French</span>
    </a>
    <a class="dropdown-item" href="#">
      <i class="flag-icon flag-icon-es mr-2"></i>
      <span>Spanish</span>
    </a>
  </div>
</pf-dropdown>
