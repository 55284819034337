import {Component} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent {
  public currentYear: string = DateTime.now().toFormat('y');
}
